* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

html, body, #root, .App {
  min-height: 100vh; 
  margin: 0;
  padding: 0;
  width: 100%; 
}

:root {
  --primary-color-light: #ffffff; /* Цвет фона для светлой темы */
  --text-color-light: #000000;   /* Цвет текста для светлой темы */
  --primary-color-dark: #05080F; /* Цвет фона для темной темы */
  --text-color-dark: #ffffff;    /* Цвет текста для темной темы */
}

.light {
  --primary-color: var(--primary-color-light);
  --text-color: var(--text-color-light);
}

.dark {
  --primary-color: var(--primary-color-dark);
  --text-color: var(--text-color-dark);
}

body, .App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: var(--primary-color);
  color: var(--text-color);
}

