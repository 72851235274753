
.mainblock {
    text-align: center;
    margin-bottom: 32px;
}

.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    padding-bottom: 12px;
}

.desc {
    max-width: 310px;
    margin: 0 auto;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.infoText {
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
}