.header_welcome {
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_welcome.dark {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(255, 65, 108, 0.6) 100%);
}

.img {
    width: 80px;
    height: 80px;
}