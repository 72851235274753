.mainblock {
    text-align: center;
    margin-bottom: 32px;
}

.title {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    padding-bottom: 12px;
}

.desc {
    max-width: 370px;
    margin: 0 auto;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
}

.text_block {
    background: linear-gradient(86.65deg, rgba(255, 65, 108, 0.4) 0%, rgba(255, 75, 43, 0.4) 100%);
    color: #ffffff;
    padding: 16px;
    margin: 0px 12px 8px;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
}

.text_block.dark {
    background: linear-gradient(86.65deg, rgba(255, 65, 108, 0.4) 0%, rgba(255, 75, 43, 0.4) 100%);
}

.text_block.light {
    background: linear-gradient(87.99deg, rgba(125, 125, 125, 0.8) 0%, rgba(125, 125, 125, 0.5) 100%);
    ;
}

.btn_chat {
    width: -webkit-fill-available;
    background: linear-gradient(86.65deg, #FF416C 0%, #FF4B2B 100%);
    color: #ffffff;
    padding: 16px;
    margin: 0px 12px;
    border: none;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
}

.text_support {
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
}