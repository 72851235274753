.header {
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header.dark {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(142, 142, 147, 0.4) 100%);
}

.header-success {
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.header-success.dark {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(48, 209, 88, 0.4) 100%);
}

.header-error {
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(255, 69, 58, 0.4) 100%);
}

.header-error.dark {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(255, 69, 58, 0.4) 100%);
}

.img {
    width: 80px;
    height: 80px;
}